///////////////////////////////////////////////////////////////////////////////////////////////////////

//  見出しクラス

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c {
  //h2 default
  &-ttl2 {
    color: $keyColor;
    line-height: 1.5;
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 40px;
    text-align: center;
    @include mq-max(xs) {
      font-size: 22px;
    }
  }

  //h3 default
  &-ttl3 {
    color: $keyColor;
    line-height: 1.5;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 40px;
    text-align: center;
    @include mq-max(xs) {
      font-size: 22px;
    }
  }

}