.l-header {
  background-color: $white;
  @if $is-fixed == true {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
  &__inner {
    &-top {
      position: relative;
      width: 100%;
      background: $bg_grayLight;
      padding-bottom: 20px;
      border-bottom: 2px solid $keyColor;
      height: 100px;
      overflow: hidden;
    }
    &-bottom {
      width: 100%;
      max-width: $contentWidth;
      margin: 0 auto;
    }
    @include mq-max(md) {
      display: block;
      padding: 0;
      position: fixed;
      top: 0;
      background: transparent;
    }
  }

  h1,.logo {
    margin-bottom: 0;
    margin-right: 30px;
    line-height: 1;
    display: inline-block;
    @include mq-max(md) {
      margin: 0;
      line-height: 0;
    }
    img {
      width: 100%;
    }
  }
  &__caption {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .logo {
    img {
      opacity: 1;
      transition: opacity $transition;
    }
    &:hover {
      img {
        opacity: .7;
      }
    }
  }

  &__modules {
    max-width: $contentWidth;
    margin: 0 auto;
    &-contact {
      position: absolute;
      display: flex;
      right: 0;
      top: 0;
      height: 100%;
      //      background: #000;

    }
    @include mq-max(md) {
      padding: 10px;
      background-color: $white;
      line-height: 0;
    }
  }
&__companyArea {
      align-self:flex-end;
    &-list {
  margin-bottom: 10px;
        li {
          position: relative;
          &:before {
          @include fa('f054');
          position: absolute;
          top: 58%;
          left: 0;
          transform: translateY(-50%);
          font-weight: 600;
          line-height: 1;
          transition: all $transition;
          color: $keyColorBright;
          font-size: 80%;
          }

        a {
          color: $keyColorBright !important;
          padding-left: 10px;
          &:after {
            display: none;
          }
          }
    }
  }

}
&__telArea {
  padding: 10px 20px;
  p {
    margin-bottom: 0;
    font-size: 12px;
  }
  &-list {
    font-size: 23px;
    font-weight: 600;
    line-height: 1.26;
    li {
      position: relative;
      &:before {
        @include fa('f095');
        position: absolute;
        top: 55%;
        left: 0;
        transform: translateY(-50%);
        font-weight: 600;
        line-height: 1;
        transition: all $transition;
        color: $keyColorBright;
        font-size: 90%;
      }
      a {
        color: $keyColorBright !important;
        padding-left: 23px;
      }
    }
  }
}
&__btnArea {
  display: flex;
  li {
    display: flex;
  }
  a {
    font-size: 23px;
    color: $white;
    padding:32px 20px;
  }
  &-order {
    a {
      background: $accentColor;
    }
  }
  &-contact {
    a {
      background: $keyColor;

    }
  }
}
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ハンバーガーメニュー

///////////////////////////////////////////////////////////////////////////////////////////////////////
.c-toggleBtn {
  @include mq-max(md) {
    display: block;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 42px;
    height: 42px;
    cursor: pointer;
    z-index: 3;
    span{
      display: block;
      position: absolute;
      width: 30px;
      border-bottom: solid 3px $black;
      transition: .35s ease-in-out; //変化の速度を指定
      left: 6px;
      &:nth-child(1){
        top: 9px;
      }
      &:nth-child(2){
        top: 18px;
      }
      &:nth-child(3){
        top: 27px;
      }
    }
    &.is-active{
      span{
        &:nth-child(1){
          top: 18px;
          left: 6px;
          transform: rotate(-45deg);
        }
        &:nth-child(2),&:nth-child(3){
          top: 18px;
          transform: rotate(45deg);
        }
      }
    }
  }
}
